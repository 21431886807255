import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ApiCall } from "../../../../services/middleware";
import { consoleLog, SetDOBFormat } from "../../../../services/common-function";
import { Decoder } from "../../../../services/auth";
import { CommonData, ErrorCode } from "../../../../services/constant";
import "../../ManageReports/Manage_Reports.css";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ImageName } from "../../../../enums";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FcSearch } from "react-icons/fc";
import { INVOICE_PATH_ONLY } from "../../../../services/config/api_url";
import ViewDynamicReportTable from "./ViewDynamicReportTable";
import DateRangeType from "./AllFilterModule/DateRangeType";
import ServiceType from "./AllFilterModule/ServiceType";
import ClientFilter from "./AllFilterModule/ClientFilter";
import DepartmentFilter from "./AllFilterModule/DepartmentFilter";
import LeiFilter from "./AllFilterModule/LeiFilter";
import VendorFilter from "./AllFilterModule/VendorFilter";
import LanguageFilter from "./AllFilterModule/LanguageFilter";
import VendorBirthdateFilter from "./AllFilterModule/VendorBirthdateFilter";
import ClientRatecardFilter from "./AllFilterModule/ClientRatecardFilter";
import VendorRatecardFilter from "./AllFilterModule/VendorRatecardFilter";
import VendorRatingFilter from "./AllFilterModule/VendorRatingFilter";
import VendorStatusFilter from "./AllFilterModule/VendorStatusFilter";
import JobTypeFilter from "./AllFilterModule/JobTypeFilter";
import AppointmentFilter from "./AllFilterModule/AppointmentFilter";
import JobStatusFilter from "./AllFilterModule/JobStatusFilter";
import JobDateFilter from "./AllFilterModule/JobDateFilter";
import AssignVendorFilter from "./AllFilterModule/AssignVendorFilter";
import BookingFeeFilter from "./AllFilterModule/BookingFeeFilter";
import { toast } from "react-toastify";
import VendorAddressFilter from "./AllFilterModule/VendorAddressFilter";
import VendorMileageFilter from "./AllFilterModule/VendorMileageFilter";
import JobLocationFilter from "./AllFilterModule/JobLocationFilter";
import AdminAppointmentDetailsFilter from "./AllFilterModule/AdminAppointmentDetailsFilter";
import VendorAppointmentDetailsFilter from "./AllFilterModule/VendorAppointmentDetailsFilter";
import BillingLocationFilter from "./AllFilterModule/BillingLocationFilter";
import ClientProfileNotesFilter from "./AllFilterModule/ClientProfileNotesFilter";
import JobDurationFilter from "./AllFilterModule/JobDurationFilter";
import InvoiceNumberFilter from "./AllFilterModule/InvoiceNumberFilter";
import InvoiceStatusFilter from "./AllFilterModule/InvoiceStatusFilter";
import InvoiceAmountFilter from "./AllFilterModule/InvoiceAmountFilter";
import ProjectNameFilter from "./AllFilterModule/ProjectNameFilter";
import ProjectStatusFilter from "./AllFilterModule/ProjectStatusFilter";
import TaskFilter from "./AllFilterModule/TaskFilter";
import ProjectOwnerFilter from "./AllFilterModule/ProjectOwnerFilter";
import InternalProjectStatusFilter from "./AllFilterModule/InternalProjectStatusFilter";
import InternalProjectNotes from "./AllFilterModule/InternalProjectNotesFilter";
import InternalJobOrProjectFilter from "./AllFilterModule/InternalJobOrProjectFilter";
import AllJobsFilter from "./AllFilterModule/AllJobsFilter";
import AllProjectsFilter from "./AllFilterModule/AllProjectsFilter";
import { SelectBox } from "../../SharedComponents/inputText";

function ViewDynamicReport(props) {
    let { id } = useParams();

    const [key, setKey] = useState("columns");
    const [dynamicData, setDynamicData] = useState({});
    const [listData, setListData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [filterDate, setFilterDate] = useState("");
    const [filterText, setFilterText] = useState("");
    const [filePath, setFilePath] = useState("");
    const [reportId, setReportId] = useState("");
    const [reportStatus, setReportStatus] = useState("");
    const [sortData, setSortData] = useState([]);
    const [selectedSortData, setSelectedSortData] = useState({});
    useEffect(() => {
        getDynamicDataView(id);
        setReportId(id);
    }, []);

    async function getDynamicDataView(id) {
        let payload = {
            id: id
        }
        let res = await ApiCall("reportdetails", payload);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            consoleLog("Report Details>>> ", payload.data);
            if (payload.data.details[0].fieldData !== null) {
                let obj = JSON.parse(payload.data.details[0].fieldData),
                    temp = {},
                    arr = [];
                obj.fields.map((item) => {
                    arr.push(item);
                });

                obj.fields = arr;

                consoleLog("New Dynamic OBJ >>> ", obj);
                setDynamicData(obj);
                getSortingListData(obj);
            }
            setListData(payload.data.list);
            if (payload.data.details[0].filePath !== null) {
                setFilePath(payload.data.details[0].filePath);
            }
            if (payload.data.details[0].processStat !== null) {
                setReportStatus(payload.data.details[0].processStat);
            }
        }
    }

    const loaderChange = async (type) => {
        setLoader(type);
    }

    const getSortingListData = (obj) => {
        let arr = [];
        if (obj.fields !== undefined) {
            obj.fields.map((item) => {
                arr.push({
                    label: item.label + ' (A-Z)',
                    value: item.name + 'asc',
                    name: item.name,
                    sort: 'asc'
                },
                    {
                        label: item.label + ' (Z-A)',
                        value: item.name + 'desc',
                        name: item.name,
                        sort: 'desc'
                    })
            });
            setSortData(arr);

            if (obj.sort !== null) {
                let temp = arr.find((item) => item.value === obj.sort.field + obj.sort.order);
                // console.log("SelectedSortingData >>>> ", temp);
                setSelectedSortData(temp);
                // if (obj.sort.order === "asc") {
                //     setSelectedSortData({
                //         label: obj.sort.field + ' (A-Z)',
                //         value: obj.sort.field + 'asc',
                //         name: obj.sort.field,
                //         sort: 'asc'
                //     });
                // } else {
                //     setSelectedSortData({
                //         label: obj.sort.field + ' (Z-A)',
                //         value: obj.sort.field + 'desc',
                //         name: obj.sort.field,
                //         sort: 'desc'
                //     });
                // }
            }
        }
    }

    const getReportStatus = (id) => {
        let str = "";
        if (id === 0) {
            str = "Pending";
        } else if (id === 1) {
            str = "In-progress";
        } else if (id === 2) {
            str = "Completed";
        }
        return str;
    }

    async function changeShowQuestion(e, pos) {
        let dd = dynamicData;
        let data = dd.fields;
        if (e.target.checked) {
            data[pos].display = true;
        } else {
            data[pos].display = false
        }
        dd.fields = data;
        await loaderChange(true);
        setDynamicData(dd);
        await loaderChange(false);
    }

    async function changeFilterCheck(e, pos) {
        let dd = dynamicData;
        let data = dd.fields;
        // console.log("check >>> ", data)
        if (e.target.checked) {
            data[pos].filterCheck = true;
            addFilter(data[pos].name, "");
        } else {
            data[pos].filterCheck = false;
            deleteFilter(data[pos].name);
        }
        dd.fields = data;
        await loaderChange(true);
        setDynamicData(dd);
        await loaderChange(false);
    }

    function renderingTableView(value) {
        return (
            <ViewDynamicReportTable data={value} list={listData} />
        )
    }

    function appointmentdateChange(date) {
        setFilterDate(SetDOBFormat(date))
    }

    function filterTextChange(e, field) {
        // console.log(e.target.value);
        setFilterText(e.target.value);
        // let temp = {},
        //     value = e.target.value
        // arr = [];

        // temp[field] = {
        //     op: "=",
        //     val: value
        // }

        // arr.push(temp);
        // consoleLog("fieldsetArray >>> ", arr)
    }

    async function addFilter(name, value) {
        let obj = dynamicData;

        obj.where[name] = {
            op: "",
            value: [value]
        }
        await loaderChange(true);
        // console.log("After Add Filter Obj >>>> ", obj);
        setDynamicData(obj);
        await loaderChange(false);
    }

    async function deleteFilter(name) {
        let obj = dynamicData;

        for (let key in obj.where) {
            // console.log(name)
            if (key === name) {
                delete obj.where[key];
            }
        }
        await loaderChange(true);
        setDynamicData(obj);
        await loaderChange(false);
    }

    function onExport() {
        window.open(INVOICE_PATH_ONLY + filePath, "_blank");
    }

    async function AddCondition(valueObj, field) {
        let obj = dynamicData;
        obj.where[field] = valueObj
        await loaderChange(true);
        // console.log("After Add Filter Obj >>>> ", obj);
        setDynamicData(obj);
        await loaderChange(false);
    }

    function renderFilterView(data) {
        switch (data.name) {
            case 'dateRange': return (<>
                <DateRangeType data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where}/>
            </>)
            case 'serviceType': return (<>
                <ServiceType data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} stype="Job" />
            </>)
            case 'client': return (<>
                <ClientFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'department': return (<>
                <DepartmentFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'clientRateCard': return (<>
                <ClientRatecardFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'lei': return (<>
                <LeiFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendor': return (<>
                <VendorFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'language': return (<>
                <LanguageFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorAddress': return (<>
                <VendorAddressFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorMileage': return (<>
                <VendorMileageFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorBirthdate': return (<>
                <VendorBirthdateFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorRateCard': return (<>
                <VendorRatecardFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorRating': return (<>
                <VendorRatingFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorStatus': return (<>
                <VendorStatusFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'jobType': return (<>
                <JobTypeFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'appointmentType': return (<>
                <AppointmentFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'allJobs': return (<>
                <AllJobsFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'jobLocation': return (<>
                <JobLocationFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'jobStatus': return (<>
                <JobStatusFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'jobDate': return (<>
                <JobDateFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'actualDuration': return (<>
                <JobDurationFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'adminAppointmentDetails': return (<>
                <AdminAppointmentDetailsFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorAppointmentDetails': return (<>
                <VendorAppointmentDetailsFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'vendorAssignDate': return (<>
                <JobDateFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'billinglocation': return (<>
                <BillingLocationFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'clientProfileNotes': return (<>
                <ClientProfileNotesFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'assignVendor': return (<>
                <AssignVendorFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'bookingFee': return (<>
                <BookingFeeFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'receivableInvoice': return (<>
                <InvoiceNumberFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'receivableStatus': return (<>
                <InvoiceStatusFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'totalReceivableAmount': return (<>
                <InvoiceAmountFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'ReceivableMiscFees': return (<>
                <InvoiceAmountFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'payableInvoice': return (<>
                <InvoiceNumberFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'payableStatus': return (<>
                <InvoiceStatusFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'totalPayableAmount': return (<>
                <InvoiceAmountFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'PayableMiscFees': return (<>
                <InvoiceAmountFilter data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'receivableInvoiceDate': return (<>
                <DateRangeType data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'projectStatus': return (<>
                <ProjectStatusFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'projectId': return (<>
                <AllProjectsFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'projectName': return (<>
                <ProjectNameFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'task': return (<>
                <TaskFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'sourceLanguage': return (<>
                <LanguageFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'targetLanguage': return (<>
                <LanguageFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'dueDate': return (<>
                <DateRangeType data={data} onChangedate={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'projectOwner': return (<>
                <ProjectOwnerFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'projectTitle': return (<>
                <ProjectNameFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'internalProjectStatus': return (<>
                <InternalProjectStatusFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'internalProjectDescription': return (<>
                <InternalProjectNotes data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'internalProjectNotes': return (<>
                <InternalProjectNotes data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
            case 'jobOrProject': return (<>
                <InternalJobOrProjectFilter data={data} onFilterChange={(obj) => AddCondition(obj, data.name)} selValue={dynamicData.where} />
            </>)
        }
    }

    const applyFilter = async (type) => {
        let req = {
            id: reportId,
            fieldData: dynamicData,
            saveType: type
        }

        // consoleLog("Req >>> ", req);
        let res = await ApiCall("updateReportFilterValue", req);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            // console.log("Success >>> ", res);
            toast.success("Filter Applied Successfully");
            getDynamicDataView(id);
        }
    }

    const sortDataChange = (value) => {
        setSelectedSortData(value);
        let obj = dynamicData;
        obj.sort = {
            field: value.name,
            order: value.sort
        };
        // console.log("Sort Data >>> ", obj);
        setDynamicData(obj);
    }
    return (<>
        <div className="component-wrapper">
            <div className="row">
                <div className="col-md-12">
                    <div className="report-top-part">
                        <div
                            className="vn_frm"
                            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                        >
                            {" "}
                            <Link to="/adminDashboard">Dashboard</Link> /
                            <Link to="/customReports"> Custom Reports </Link>
                            / View Reports
                        </div>
                        <div className="table-filter-app-b">
                        </div>

                    </div>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <div className="cust_rp_st">
                        {reportStatus === "" ? <></> : <>
                            <span>Report Status : {getReportStatus(reportStatus)}</span>
                        </>
                        }
                    </div>
                </div>
                <div className="col-md-3">
                    <span style={{ display: "inline-block" }}>Sort by : </span>
                    <div style={{ display: "inline-block", paddingLeft: "15px" }}>
                        <SelectBox
                            optionData={sortData}
                            value={selectedSortData}
                            onSelectChange={(value) => sortDataChange(value)}
                        ></SelectBox>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="table-filter-box">
                        <div className="export-btn" >

                            <Button variant="primary" onClick={() => applyFilter("0")} disabled={reportStatus === 1}>Save</Button>{' '}
                            <Button variant="primary" onClick={() => applyFilter("1")} disabled={reportStatus === 1}>Save & Run</Button>{' '}
                        </div>
                    </div>
                </div>
                {filePath !== "" ?
                    <div className="col-md-12">
                        <div className="table-filter-app-b">
                            <div className="table-filter-box">
                                <div className="export-btn" >
                                    <a href="javascript:void(0)" onClick={() => onExport()}>
                                        Export{" "}
                                        <img
                                            src={ImageName.IMAGE_NAME.EXPORT_BTN}
                                            style={{ width: "25px", cursor: "pointer" }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> : <></>
                }
                <div className="col-md-3" >
                    <div className="col-filter-wrap mg-report">
                        <Tabs
                            defaultActiveKey="columns"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="columns" title="Columns">
                                <div className="Filters-wrap">
                                    <h2>Columns</h2>
                                    <p>Choose the columns to display list view</p>
                                    <ul>{Object.keys(dynamicData).length > 0 ? <>
                                        {dynamicData.fields.map((data, i) => <>
                                            <li key={i}>
                                                <input type="checkbox" id={data.name + "_" + i} name={data.name} value={data.name} defaultChecked={data.display} onChange={(e) => changeShowQuestion(e, i)} />
                                                <label htmlFor={data.name + "_" + i}> {data.label}</label>
                                            </li>
                                        </>
                                        )} </> : <></>}
                                    </ul>

                                    {/* <div className="clear-bt-wrap">
                                        <a className="clear-bt white-btn">Cancel</a>
                                        <a className="apply-bt blue-btn">Save</a>
                                        <a className="reset-bt blue-btn">Reset to default</a>

                                    </div> */}
                                </div>
                            </Tab>
                            <Tab eventKey="filter" title="Filter">
                                <div className="Filters-wrap">
                                    <h2>Filters</h2>
                                    <ul>
                                        {Object.keys(dynamicData).length > 0 ? <>
                                            {dynamicData.fields.map((data, i) => <>
                                                <li key={i}>
                                                    <input
                                                        type="checkbox"
                                                        id={data.name + "_" + i}
                                                        name={data.name}
                                                        value={data.name}
                                                        defaultChecked={data.filterCheck}
                                                        onChange={(e) => changeFilterCheck(e, i)} />
                                                    <label htmlFor={data.name + "_" + i}> {data.label}</label>
                                                </li>
                                                {data.filterCheck ? <>
                                                    {renderFilterView(data)}
                                                </> : <></>}
                                            </>)} </> : <></>
                                        }
                                    </ul>

                                    {/* <div className="clear-bt-wrap">
                                        <a className="clear-bt white-btn">Clear</a>
                                        <a className="apply-bt blue-btn">Apply</a>
                                    </div> */}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="col-md-9" >
                    <div className="manage-reports-container">
                        <div className="table-listing-app">
                            <div className="table-responsive">
                                {loader ? null :
                                    <React.Fragment>
                                        {renderingTableView(dynamicData)}</React.Fragment>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);

}

export default ViewDynamicReport;

function Schedule(props) {
    const { onClick } = props;
    return (
        <img
            style={{
                width: "35px",
                height: "37px",
                borderRadius: "4px",
                cursor: "pointer",
            }}
            src={ImageName.IMAGE_NAME.CALENDER4}
            onClick={onClick}
        />
    );
}