import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { CommonData } from "../../../../../services/constant";

function InvoiceStatusFilter(props) {
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {

        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                if (filterObj.value.length > 0) {
                    let arr = [];
                    for (let temp of filterObj.value) {
                        for (let data of CommonData.COMMON.INVOICE_STATUS_FILTER) {
                            if (data.value == temp) {
                                arr.push(data);
                            }
                        }
                    }
                    setSelectedOption(arr);
                }
            }
        }
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: props.data.name,
            value: data
        }
        return props.onFilterChange(obj);
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={CommonData.COMMON.INVOICE_STATUS_FILTER}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default InvoiceStatusFilter;