import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { ApiCall } from "../../../../../services/middleware";
import { ErrorCode } from "../../../../../services/constant";
import { Decoder } from "../../../../../services/auth";

function ProjectOwnerFilter(props) {
    const [allData, setAllData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        getListData();
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'projectOwner',
            value: data
        }
        return props.onFilterChange(obj);
    }

    const getListData = async () => {
        let reqData = {
            reqUserTypeId: 2,
        };
        let res = await ApiCall("getUserListByUserType", reqData);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let decodeData = Decoder.decode(res.data.payload);
            let listData = decodeData.data.userList;
            let arr = [];
            listData.map((ll) => {
                arr.push({
                    label: ll.name,
                    value: ll.userId
                })
            })

            setAllData(arr);

            if (props.selValue !== undefined) {
                if (props.selValue[props.data.name] !== undefined) {
                    let filterObj = props.selValue[props.data.name];
                    if (filterObj.value.length > 0) {
                        let temparr = [];
                        for (let temp of filterObj.value) {
                            for (let data of arr) {
                                if (data.value == temp) {
                                    temparr.push(data);
                                }
                            }
                        }
                        setSelectedOption(temparr);
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allData}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default ProjectOwnerFilter;