import { useEffect, useState } from "react";
import { CommonData } from "../../../../../services/constant";
import { SelectBox } from "../../../SharedComponents/inputText";

function ServiceType(props) {
    const [value, setValue] = useState({
        label: props.stype,
        value: props.stype
    });

    useEffect(() => {
        setFilterValues(value.value);
    }, []);
    const valueChange = (value) => {
        setValue(value);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: value.value,
            value: [data]
        }
        return props.onFilterChange(obj);
    }

    return (
        <>
            <div className="web-form-bx">
                <SelectBox
                    optionData={CommonData.COMMON.ALL_SERVICE_TYPE}
                    value={value}
                    onSelectChange={(value) => valueChange(value)}
                    isDisabled={true}
                ></SelectBox>
            </div>
        </>
    )
}

export default ServiceType;