import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { ApiCall } from "../../../../../services/middleware";
import { ErrorCode } from "../../../../../services/constant";
import { Decoder } from "../../../../../services/auth";

function AllProjectsFilter(props) {
    const [allData, setAllData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        getListData();
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'projectId',
            value: data
        }
        return props.onFilterChange(obj);
    }

    const getListData = async () => {
        let allDataArr = [];

        let leiRes = await ApiCall("getAllProjectId");
        if (
            leiRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            leiRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(leiRes.data.payload);
            let response = payload.data.data;
            for (let k = 0; k < response.length; k++) {
                allDataArr.push({
                    label: response[k].requestId,
                    value: response[k].requestId,
                });
            }
            setAllData(allDataArr);

            if (props.selValue !== undefined) {
                if (props.selValue[props.data.name] !== undefined) {
                    let filterObj = props.selValue[props.data.name];
                    if (filterObj.value.length > 0) {
                        let arr = [];
                        for (let temp of filterObj.value) {
                            for (let data of allDataArr) {
                                if (data.value == temp) {
                                    arr.push(data);
                                }
                            }
                        }
                        setSelectedOption(arr);
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allData}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default AllProjectsFilter;