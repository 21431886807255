import React, { useEffect, useState } from "react";
import { CommonData } from "../../../../../services/constant";
import { SelectBox } from "../../../SharedComponents/inputText";
import { decimalValue } from "../../../../../services/common-function";
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";

function InvoiceAmountFilter(props) {
    const [value, setValue] = useState({ label: "", value: "" });
    const [specificData, setSpecificData] = useState("");
    const [lowData, setLowData] = useState("");
    const [highData, setHighData] = useState("");

    useEffect(() => {
        prevSelectedValue();
    }, []);

    const prevSelectedValue = () => {
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                setValue({ label: filterObj.op, value: filterObj.op });
                if (filterObj.op === "Amount range") {
                    setLowData(filterObj.value[0]);
                    setHighData(filterObj.value[1]);
                } else {
                    setSpecificData(filterObj.value[0]);
                }
            }
        }
    }

    const changeOption = (value) => {
        setValue(value);
        setSpecificData("");
        // if (value.value !== "Specific amount" && value.value !== "Amount range") {
        //     let obj = {
        //         op: value.value,
        //         value: [value.value]
        //     }
        //     return props.onChangedate(obj);
        // }
    }

    const rateChange = (e) => {
        let value = e.target.value;
        if (decimalValue(value)) {
            setSpecificData(value);
        }

    }

    const applyRateFilter = () => {
        setFilterValues(specificData);
    }

    const lowRateChange = (e) => {
        let value = e.target.value;
        setLowData(value);
    }

    const highRateChange = (e) => {
        let value = e.target.value;
        setHighData(value);
    }

    const setRateFilter = () => {
        let errorCount = 0;
        if (lowData === undefined || lowData === null || lowData === "") {
            toast.error("Please select low amount");
            errorCount++;
        } else if (highData === undefined || highData === null || highData === "") {
            toast.error("Please select high amount");
            errorCount++;
        } else if (Number(lowData) > Number(highData)) {
            toast.error("low amount should be less than high amount");
            errorCount++;
        }
        if (errorCount === 0) {
            let obj = {
                op: value.value,
                value: [lowData, highData]
            }
            return props.onChangedate(obj);
        }
    }

    const setFilterValues = (data) => {
        let obj = {
            op: value.value,
            value: [data]
        }
        return props.onChangedate(obj);
    }

    const renderSelectedView = (value) => {
        if (value.value !== "Amount range") {
            return (<div className="web-form-bx">
                <div className="frm-label">
                    Specified Amount *
                </div>

                <div class="input-group mb-3">
                    <input
                        type="text"
                        value={specificData}
                        name=""
                        placeholder="Enter amount"
                        className="in-field3_vr"
                        onChange={rateChange}
                        style={{ fontSize: "14px" }}
                    />
                    <span class="input-group-text">$</span>
                </div>

                <Button variant="primary" onClick={() => applyRateFilter()} style={{ paddingBottom: "10px", paddingTop: "5px" }}>Apply</Button>
            </div>
            )
        } else if (value.value === "Amount range") {
            return (
                <>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            Low Amount *
                        </div>

                        <div class="input-group">
                            <input
                                type="text"
                                value={lowData}
                                name=""
                                placeholder="Enter low amount"
                                className="in-field3_vr"
                                onChange={lowRateChange}
                                style={{ fontSize: "14px" }}
                            />
                            <span class="input-group-text">$</span>
                        </div>
                    </div>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            High Amount *
                        </div>

                        <div class="input-group mb-3">
                            <input
                                type="text"
                                value={highData}
                                name=""
                                placeholder="Enter high amount"
                                className="in-field3_vr"
                                onChange={highRateChange}
                                style={{ fontSize: "14px" }}
                            />
                            <span class="input-group-text">$</span>
                        </div>
                        <Button variant="primary" onClick={() => setRateFilter()} style={{ paddingBottom: "10px" }}>Apply</Button>
                    </div>
                </>
            )
        } else {
            return <></>
        }

    }

    return (<>
        <div className="web-form-bx">
            <SelectBox
                optionData={CommonData.COMMON.INVOICE_AMOUNT_FILTER}
                value={value}
                onSelectChange={(value) => changeOption(value)}
            ></SelectBox>
        </div>
        {value.value !== "" ? renderSelectedView(value) : <></>}
    </>
    )
}


export default InvoiceAmountFilter;