import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { ApiCall } from "../../../../../services/middleware";
import { ErrorCode } from "../../../../../services/constant";
import { Decoder } from "../../../../../services/auth";

function AssignVendorFilter(props) {
    const [allData, setAllData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        getListData();
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'assignVendor',
            value: [data]
        }
        return props.onFilterChange(obj);
    }

    const getListData = async () => {

        let vendorArrMainData = [];

        let vendorRes = await ApiCall("fetchAllVendorList", {});

        if (
            vendorRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            vendorRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(vendorRes.data.payload);

            //   consoleLog("*****", payload.data);
            let vendorArrData = payload.data.vendorList;
            vendorArrData.map((obj) => {
                vendorArrMainData.push({
                    label: obj.name,
                    value: obj.userId,
                });
            });
            setAllData(vendorArrMainData);
        }
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allData}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default AssignVendorFilter;