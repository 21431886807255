import React, { useEffect, useState } from "react";
import { CommonData } from "../../../../../services/constant";
import { SelectBox } from "../../../SharedComponents/inputText";
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";

function VendorRatingFilter(props) {
    const [value, setValue] = useState({ label: "", value: "" });
    const [ratingOption, setRatingOption] = useState([]);
    const [specificRating, setSpecificRating] = useState({ label: "", value: "" });
    const [lowRating, setLowRating] = useState({ label: "", value: "" });
    const [highRating, setHighRating] = useState({ label: "", value: "" });

    useEffect(() => {
        let arr = [];
        for (let i = 0; i <= 5; i++) {
            arr.push({
                label: i.toString(),
                value: i.toString()
            })
        }
        setRatingOption(arr);
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                setValue({ label: filterObj.op, value: filterObj.op });
                if (filterObj.op !== "Rating range") {
                    setSpecificRating({ label: filterObj.value[0], value: filterObj.value[0] });
                } else {
                    setLowRating({ label: filterObj.value[0], value: filterObj.value[0] });
                    setHighRating({ label: filterObj.value[1], value: filterObj.value[1] });
                }
            }
        }
    }, []);

    const changeOption = (value) => {
        setValue(value);
        setSpecificRating({ label: "", value: "" });
        // if (value.value !== "Specific rating" && value.value !== "Rating range") {
        //     let obj = {
        //         op: value.value,
        //         value: [value.value]
        //     }
        //     return props.onChangedate(obj);
        // }
    }

    const changeSpecificRating = (obj) => {
        setSpecificRating(obj);
        setFilterValues(obj.value);
    }

    const lowRatingChange = (obj) => {
        setLowRating(obj);
    }

    const highRatingChange = (obj) => {
        setHighRating(obj);
    }

    const setRatingFilter = () => {
        let errorCount = 0;
        if (lowRating.value === undefined || lowRating.value === null || lowRating.value === "") {
            toast.error("Please select low rating");
            errorCount++;
        } else if (highRating.value === undefined || highRating.value === null || highRating.value === "") {
            toast.error("Please select high rating");
            errorCount++;
        } else if (Number(lowRating.value) > Number(highRating.value)) {
            toast.error("low rating should be less than high rating");
            errorCount++;
        }
        if (errorCount === 0) {
            let obj = {
                op: value.value,
                value: [lowRating.value, highRating.value]
            }
            return props.onChangedate(obj);
        }
    }

    const setFilterValues = (data) => {
        let obj = {
            op: value.value,
            value: [data]
        }
        return props.onChangedate(obj);
    }

    const renderSelectedView = (value) => {
        if (value.value !== "Rating range") {
            return (<div className="web-form-bx">
                <div className="frm-label">
                    Select Specified Rating *
                </div>
                <SelectBox
                    optionData={ratingOption}
                    value={specificRating}
                    onSelectChange={(value) => changeSpecificRating(value)}
                ></SelectBox>
            </div>
            )
        } else if (value.value === "Rating range") {
            return (
                <>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            Low Rating *
                        </div>
                        <SelectBox
                            optionData={ratingOption}
                            value={lowRating}
                            onSelectChange={(value) => lowRatingChange(value)}
                        ></SelectBox>
                    </div>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            High Rating *
                        </div>

                        <SelectBox
                            optionData={ratingOption}
                            value={highRating}
                            onSelectChange={(value) => highRatingChange(value)}
                        ></SelectBox>
                        <Button variant="primary" onClick={() => setRatingFilter()} style={{ paddingBottom: "10px", marginTop: "10px" }}>Apply</Button>
                    </div>
                </>
            )
        } else {
            return <></>
        }

    }

    return (<>
        <div className="web-form-bx">
            <SelectBox
                optionData={CommonData.COMMON.RATING_FILTER}
                value={value}
                onSelectChange={(value) => changeOption(value)}
            ></SelectBox>
        </div>
        {value.value !== "" ? renderSelectedView(value) : <></>}
    </>
    )
}


export default VendorRatingFilter;