import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { ApiCall } from "../../../../../services/middleware";
import { ErrorCode } from "../../../../../services/constant";
import { Decoder } from "../../../../../services/auth";

function DepartmentFilter(props) {
    const [allData, setAllData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        getAllActiveDepartments();
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'department',
            value: data
        }
        return props.onFilterChange(obj);
    }

    const getAllActiveDepartments = async () => {
        let allFilterSubDept = [];
        let activeDepartment = [];

        // ..... All Department fetch.........
        // let activeDept = await ApiCall("fetchActiveClientDepartmentAll");
        let activeDept = await ApiCall("fetchClientDeptWithClientName");
        if (
            activeDept.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            activeDept.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            activeDepartment = Decoder.decode(activeDept.data.payload);
            // consoleLog("All Department payload>>>>>", activeDepartment);
            if (activeDepartment.data.length > 0) {
                for (let j = 0; j < activeDepartment.data.length; j++) {
                    allFilterSubDept.push({
                        label: activeDepartment.data[j].deptName,
                        value: activeDepartment.data[j].id,
                    });
                }
                setAllData(allFilterSubDept);

                if (props.selValue !== undefined) {
                    if (props.selValue[props.data.name] !== undefined) {
                        let filterObj = props.selValue[props.data.name];
                        if (filterObj.value.length > 0) {
                            let arr = [];
                            for (let temp of filterObj.value) {
                                for (let data of allFilterSubDept) {
                                    if (data.value == temp) {
                                        arr.push(data);
                                    }
                                }
                            }
                            setSelectedOption(arr);
                        }
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allData}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default DepartmentFilter;