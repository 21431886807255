import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';

function ClientProfileNotesFilter(props) {
    const [value, setValue] = useState("");

    useEffect(() => {
        prevSelectedValue();
    }, []);

    const prevSelectedValue = () => {
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                if (filterObj.value.length > 0) {
                    setValue(filterObj.value[0]);
                }
            }
        }
    }
    const onValueChange = (e) => {
        let data = e.target.value;
        setValue(data);
    }

    const apply = () => {
        setFilterValues();
    }

    const setFilterValues = () => {
        let obj = {
            op: 'clientProfileNotes',
            value: [value]
        }
        return props.onFilterChange(obj);
    }

    return (
        <>
            <div className="web-form-bx">
                <div className="frm-label">
                    Enter Notes *
                </div>

                <input
                    type="text"
                    value={value}
                    name=""
                    placeholder="Enter notes"
                    className="in-field3_vr"
                    onChange={onValueChange}
                    style={{ marginBottom: "15px", fontSize: "14px" }}
                />

                <Button variant="primary" onClick={() => apply()} style={{ paddingBottom: "10px", paddingTop: "5px" }}>Apply</Button>
            </div>
        </>
    )
}

export default ClientProfileNotesFilter;