import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { getLookUpDataFromAPI } from "../../../../../services/common-function";
import { ImageName } from "../../../../../enums";

function AppointmentFilter(props) {
    const [allData, setAllData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        getListData();
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'appointmentType',
            value: data
        }
        return props.onFilterChange(obj);
    }

    const getListData = async () => {
        let allLookupValue = [];
        let allAppointmentType = [];

        allLookupValue = await getLookUpDataFromAPI();


        allLookupValue.SCHEDULE_TYPE.map((data) => {
            if (data.id === 63) {
                allAppointmentType.push({
                    label: (
                        <div>
                            <img
                                src={ImageName.IMAGE_NAME.F2F}
                                height="30px"
                                width="25px"
                                style={{ float: "Left" }}
                            />
                            <span style={{ paddingLeft: "5%" }}>{data.name}</span>
                        </div>
                    ),
                    value: data.id,
                });
            } else if (data.id === 64) {
                allAppointmentType.push({
                    label: (
                        <div>
                            <img
                                src={ImageName.IMAGE_NAME.VRI_ICON}
                                height="30px"
                                width="25px"
                                style={{ float: "Left" }}
                            />
                            <span style={{ paddingLeft: "5%" }}>{data.name}</span>
                        </div>
                    ),
                    value: data.id,
                });
            } else if (data.id === 65) {
                allAppointmentType.push({
                    label: (
                        <div>
                            <img
                                src={ImageName.IMAGE_NAME.OPI_ICON}
                                height="30px"
                                width="25px"
                                style={{ float: "Left" }}
                            />
                            <span style={{ paddingLeft: "5%" }}>{data.name}</span>
                        </div>
                    ),
                    value: data.id,
                });
            }
        });

        setAllData(allAppointmentType);
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                if (filterObj.value.length > 0) {
                    let arr = [];
                    for (let temp of filterObj.value) {
                        for (let data of allAppointmentType) {
                            if (data.value == temp) {
                                arr.push(data);
                            }
                        }
                    }
                    setSelectedOption(arr);
                }
            }
        }
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allData}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default AppointmentFilter;