import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { ErrorCode } from "../../../../../services/constant";
import { Decoder } from "../../../../../services/auth";
import { APP_URL } from "../../../../../services/config";
import axios from "axios";

function TaskFilter(props) {
    const [allData, setAllData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        getListData();
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'task',
            value: data
        }
        return props.onFilterChange(obj);
    }

    const getListData = async () => {
        let allTranslationService = [];

        axios.post(APP_URL.VENDOR_SERVICE_OFFERED).then((res) => {
            // console.log("RES>>>>", res);
            let respObject = res.data;
            if (
                respObject.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                respObject.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                let payload = Decoder.decode(respObject.data.payload);
                // console.log("service response payload>>>", payload);
                if (payload.data) {
                    if (payload.data.services) {
                        if (payload.data.services.length > 0) {
                            payload.data.services.map((ser) => {
                                if (
                                    ser.id === 46 &&
                                    ser.name === "Translation" &&
                                    ser.subItem.length > 0
                                ) {
                                    ser.subItem.map((item) => {
                                        allTranslationService.push({
                                            label: item.subItemName,
                                            value: item.id,
                                        });
                                    });

                                    setAllData(allTranslationService);

                                    if (props.selValue !== undefined) {
                                        if (props.selValue[props.data.name] !== undefined) {
                                            let filterObj = props.selValue[props.data.name];
                                            if (filterObj.value.length > 0) {
                                                let arr = [];
                                                for (let temp of filterObj.value) {
                                                    for (let data of allTranslationService) {
                                                        if (data.value == temp) {
                                                            arr.push(data);
                                                        }
                                                    }
                                                }
                                                setSelectedOption(arr);
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            }
        });
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allData}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default TaskFilter;