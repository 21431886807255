import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { getClientInfo } from "../../../../../services/common-function";

function ClientFilter(props) {
    const [allClients, setAllClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        prevSelectedValue()
    }, []);

    const loaderChange = async (type) => {
        setLoader(type)
    }

    const prevSelectedValue = async () => {
        let allClientArr = await getClientInfo();
        await loaderChange(true);
        setAllClients(allClientArr);
        await loaderChange(false);
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                if (filterObj.value.length > 0) {
                    let arr = [];
                    for (let temp of filterObj.value) {
                        for (let data of allClientArr) {
                            if (data.value === temp) {
                                arr.push(data)
                            }
                        }
                    }
                    setSelectedClient(arr);
                }
            }
        }
    }

    const onValueChange = (value) => {
        setSelectedClient(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'client',
            value: data
        }
        return props.onFilterChange(obj);
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allClients}
                    value={selectedClient}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default ClientFilter;