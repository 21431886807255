import React, { useEffect, useState } from "react";
import { CommonData } from "../../../../../services/constant";
import { SelectBox } from "../../../SharedComponents/inputText";
import { decimalValue } from "../../../../../services/common-function";
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";

function JobDurationFilter(props) {
    const [value, setValue] = useState({ label: "", value: "" });
    const [duration, setDuration] = useState("");
    const [lowDuration, setLowDuration] = useState("");
    const [highDuration, setHighDuratione] = useState("");

    useEffect(() => {
        prevSelectedValue();
    }, []);

    const prevSelectedValue = () => {
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                setValue({ label: filterObj.op, value: filterObj.op });
                if (filterObj.op !== "Duration range") {
                    setDuration(filterObj.value[0]);
                } else {
                    setLowDuration(filterObj.value[0]);
                    setHighDuratione(filterObj.value[1]);
                }
            }
        }
    }

    const changeOption = (item) => {
        setValue(item);
        setDuration("");
        // if (item.value !== "Specific duration" && item.value !== "Duration range") {
        //     let obj = {
        //         op: item.value,
        //         value: [item.value]
        //     }
        //     return props.onChangedate(obj);
        // }
    }

    const rateChange = (e) => {
        let value = e.target.value;
        if (decimalValue(value)) {
            setDuration(value);
        }

    }

    const applyRateFilter = () => {
        setFilterValues(duration);
    }

    const lowRateChange = (e) => {
        let value = e.target.value;
        setLowDuration(value);
    }

    const highRateChange = (e) => {
        let value = e.target.value;
        setHighDuratione(value);
    }

    const setRateFilter = () => {
        let errorCount = 0;
        if (lowDuration === undefined || lowDuration === null || lowDuration === "") {
            toast.error("Please select low duration");
            errorCount++;
        } else if (highDuration === undefined || highDuration === null || highDuration === "") {
            toast.error("Please select high duration");
            errorCount++;
        } else if (Number(lowDuration) > Number(highDuration)) {
            toast.error("low duration should be less than high duration");
            errorCount++;
        }
        if (errorCount === 0) {
            let obj = {
                op: value.value,
                value: [lowDuration, highDuration]
            }
            return props.onChangedate(obj);
        }
    }

    const setFilterValues = (data) => {
        let obj = {
            op: value.value,
            value: [data]
        }
        return props.onChangedate(obj);
    }

    const renderSelectedView = (value) => {
        if (value.value !== "Duration range") {
            return (<div className="web-form-bx">
                <div className="frm-label">
                    Enter Duration *
                </div>

                <div class="input-group mb-3">
                    <input
                        type="text"
                        value={duration}
                        name=""
                        placeholder="Enter duration"
                        className="in-field3_vr"
                        onChange={rateChange}
                        style={{ fontSize: "14px" }}
                    />
                    <span class="input-group-text">Hrs.</span>
                </div>

                <Button variant="primary" onClick={() => applyRateFilter()} style={{ paddingBottom: "10px", paddingTop: "5px" }}>Apply</Button>
            </div>
            )
        } else if (value.value === "Duration range") {
            return (
                <>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            Low Duration *
                        </div>

                        <div class="input-group">
                            <input
                                type="text"
                                value={lowDuration}
                                name=""
                                placeholder="Enter low duration"
                                className="in-field3_vr"
                                onChange={lowRateChange}
                                style={{ fontSize: "14px" }}
                            />
                            <span class="input-group-text">Hrs.</span>
                        </div>
                    </div>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            High Duration *
                        </div>

                        <div class="input-group mb-3">
                            <input
                                type="text"
                                value={highDuration}
                                name=""
                                placeholder="Enter high duration"
                                className="in-field3_vr"
                                onChange={highRateChange}
                                style={{ fontSize: "14px" }}
                            />
                            <span class="input-group-text">Hrs.</span>
                        </div>
                        <Button variant="primary" onClick={() => setRateFilter()} style={{ paddingBottom: "10px" }}>Apply</Button>
                    </div>
                </>
            )
        } else {
            return <></>
        }

    }

    return (<>
        <div className="web-form-bx">
            <SelectBox
                optionData={CommonData.COMMON.JOB_DURATION_FILTER}
                value={value}
                onSelectChange={(value) => changeOption(value)}
            ></SelectBox>
        </div>
        {value.value !== "" ? renderSelectedView(value) : <></>}
    </>
    )
}


export default JobDurationFilter;