import React, { useEffect, useState } from "react";
import { CommonData } from "../../../../../services/constant";
import { SelectBox } from "../../../SharedComponents/inputText";
import { decimalValue } from "../../../../../services/common-function";
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";

function VendorRatecardFilter(props) {
    const [value, setValue] = useState({ label: "", value: "" });
    const [rateValue, setRateValue] = useState("");
    const [lowRate, setLowRate] = useState("");
    const [highRate, setHighRate] = useState("");

    useEffect(() => {
        prevSelectedValue();
    }, []);

    const prevSelectedValue = () => {
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                setValue({ label: filterObj.op, value: filterObj.op });
                if (filterObj.op !== "Price range") {
                    setRateValue(filterObj.value[0]);
                } else {
                    setLowRate(filterObj.value[0]);
                    setHighRate(filterObj.value[1]);
                }
            }
        }
    }
    const changeOption = (value) => {
        setValue(value);
        setRateValue("");
        // if (value.value !== "Specific rate" && value.value !== "Price range") {
        //     let obj = {
        //         op: value.value,
        //         value: [value.value]
        //     }
        //     return props.onChangedate(obj);
        // }
    }

    const rateChange = (e) => {
        let value = e.target.value;
        if (decimalValue(value)) {
            setRateValue(value);
        }

    }

    const applyRateFilter = () => {
        setFilterValues(rateValue);
    }

    const lowRateChange = (e) => {
        let value = e.target.value;
        setLowRate(value);
    }

    const highRateChange = (e) => {
        let value = e.target.value;
        setHighRate(value);
    }

    const setRateFilter = () => {
        let errorCount = 0;
        if (lowRate === undefined || lowRate === null || lowRate === "") {
            toast.error("Please select low rate");
            errorCount++;
        } else if (highRate === undefined || highRate === null || highRate === "") {
            toast.error("Please select high rate");
            errorCount++;
        } else if (Number(lowRate) > Number(highRate)) {
            toast.error("low rate should be less than high rate");
            errorCount++;
        }
        if (errorCount === 0) {
            let obj = {
                op: value.value,
                value: [lowRate, highRate]
            }
            return props.onChangedate(obj);
        }
    }

    const setFilterValues = (data) => {
        let obj = {
            op: value.value,
            value: [data]
        }
        return props.onChangedate(obj);
    }

    const renderSelectedView = (value) => {
        if (value.value !== "Price range") {
            return (<div className="web-form-bx">
                <div className="frm-label">
                    Enter Rate *
                </div>

                <input
                    type="text"
                    value={rateValue}
                    name=""
                    placeholder="Enter Rate"
                    className="in-field3_vr"
                    onChange={rateChange}
                    style={{ marginBottom: "15px" }}
                />

                <Button variant="primary" onClick={() => applyRateFilter()} style={{ paddingBottom: "10px", paddingTop: "5px" }}>Apply</Button>
            </div>
            )
        } else if (value.value === "Price range") {
            return (
                <>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            Low Rate *
                        </div>

                        <input
                            type="text"
                            value={lowRate}
                            name=""
                            placeholder="Enter Rate"
                            className="in-field3_vr"
                            onChange={lowRateChange}
                        />
                    </div>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            High Rate *
                        </div>

                        <input
                            type="text"
                            value={highRate}
                            name=""
                            placeholder="Enter Rate"
                            className="in-field3_vr"
                            onChange={highRateChange}
                            style={{ marginBottom: "15px" }}
                        />
                        <Button variant="primary" onClick={() => setRateFilter()} style={{ paddingBottom: "10px" }}>Apply</Button>
                    </div>
                </>
            )
        } else {
            return <></>
        }

    }

    return (<>
        <div className="web-form-bx">
            <SelectBox
                optionData={CommonData.COMMON.RATE_CARD_FILTER}
                value={value}
                onSelectChange={(value) => changeOption(value)}
            ></SelectBox>
        </div>
        {value.value !== "" ? renderSelectedView(value) : <></>}
    </>
    )
}


export default VendorRatecardFilter;