import { useEffect, useState } from "react";
import { MultiSelectBox } from "../../../SharedComponents/inputText";
import { ApiCall } from "../../../../../services/middleware";
import { Decoder } from "../../../../../services/auth";

function LanguageFilter(props) {
    const [allData, setAllData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        getListData();
    }, []);

    const onValueChange = (value) => {
        setSelectedOption(value);
        let arr = [];
        value.map((item) => {
            arr.push(item.value);
        });
        setFilterValues(arr);
    }

    const setFilterValues = (data) => {
        let obj = {
            op: 'language',
            value: data
        }
        return props.onFilterChange(obj);
    }

    const getListData = async () => {
        let languageResArrData = [];
        let languageArrData = [];
        let languageResData = await ApiCall("getlanguagelist");
        let languagePayload = Decoder.decode(languageResData.data.payload);
        languageResArrData = languagePayload.data.languagelist;
        for (let n = 0; n < languageResArrData.length; n++) {
            languageArrData.push({
                label: languageResArrData[n].language,
                value: languageResArrData[n].id,
            });
        }
        setAllData(languageArrData);

        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                if (filterObj.value.length > 0) {
                    let arr = [];
                    for (let temp of filterObj.value) {
                        for (let data of languageArrData) {
                            if (data.value == temp) {
                                arr.push(data);
                            }
                        }
                    }
                    setSelectedOption(arr);
                }
            }
        }
    }

    return (
        <>
            <div className="web-form-bx">
                <MultiSelectBox
                    optionData={allData}
                    value={selectedOption}
                    onSelectChange={(value) => onValueChange(value)}
                ></MultiSelectBox>
            </div>
        </>
    )
}

export default LanguageFilter;