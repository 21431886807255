import React, { useEffect, useState } from "react";
import { CommonData } from "../../../../../services/constant";
import { SelectBox } from "../../../SharedComponents/inputText";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ImageName } from "../../../../../enums";
import { SetDBFormat, SetUSAdateFormatV2 } from "../../../../../services/common-function";
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";

function VendorBirthdateFilter(props) {
    const [value, setValue] = useState({ label: "", value: "" });
    const [selectedDate, setSelectedDate] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        prevSelectedValue();
    }, []);

    const prevSelectedValue = () => {
        if (props.selValue !== undefined) {
            if (props.selValue[props.data.name] !== undefined) {
                let filterObj = props.selValue[props.data.name];
                setValue({ label: filterObj.op, value: filterObj.op });
                if (filterObj.op === "Specific date") {
                    setSelectedDate(SetUSAdateFormatV2(filterObj.value[0]));
                } else if (filterObj.op === "Date range") {
                    setStartDate(SetUSAdateFormatV2(filterObj.value[0]));
                    setEndDate(SetUSAdateFormatV2(filterObj.value[1]));
                } else {
                    setStartDate("");
                    setEndDate("");
                }
            }
        }
    }
    const changeOption = (value) => {
        setValue(value);
        if (value.value !== "Specific date" && value.value !== "Date range") {
            let obj = {
                op: value.value,
                value: [value.value]
            }
            return props.onChangedate(obj);
        }
    }

    const dateChange = (date) => {
        setSelectedDate(SetUSAdateFormatV2(date));
        setFilterValues(SetDBFormat(date));
    }

    const startDateChange = (date) => {
        setStartDate(SetUSAdateFormatV2(date));
    }

    const endDateChange = (date) => {
        setEndDate(SetUSAdateFormatV2(date));
    }

    const setDateRange = () => {
        let errorCount = 0;
        if (startDate === undefined || startDate === null || startDate === "") {
            toast.error("Please select start date");
            errorCount++;
        } else if (endDate === undefined || endDate === null || endDate === "") {
            toast.error("Please select end date");
            errorCount++;
        }
        if (errorCount === 0) {
            let obj = {
                op: value.value,
                value: [startDate, endDate]
            }
            return props.onChangedate(obj);
        }
    }

    const setFilterValues = (data) => {
        let obj = {
            op: value.value,
            value: [data]
        }
        return props.onChangedate(obj);
    }

    const renderSelectedView = (value) => {
        if (value.value === "Specific date") {
            return (<div className="web-form-bx">
                <div className="frm-label">
                    Select Specified Date *
                </div>

                <div
                    className="input-group"
                    style={{
                        width: "100%",
                        borderRadius: "9px",
                        height: "43px",
                        border: "1px solid #ced4da",
                        boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                    }}
                >
                    <div style={{ width: "80%", padding: "8px" }}>
                        <span>{selectedDate}</span>
                    </div>
                    <div style={{ width: "20%" }}>
                        <a style={{ float: "right" }}>
                            <DatePicker
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                onChange={(date) => dateChange(date)}
                                customInput={<img
                                    style={{
                                        width: "35px",
                                        height: "37px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                    }}
                                    src={ImageName.IMAGE_NAME.CALENDER4}
                                />}
                            />
                        </a>
                    </div>
                </div>
            </div>
            )
        } else if (value.value === "Date range") {
            return (
                <>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            Start Date *
                        </div>

                        <div
                            className="input-group"
                            style={{
                                width: "100%",
                                borderRadius: "9px",
                                height: "43px",
                                border: "1px solid #ced4da",
                                boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                            }}
                        >
                            <div style={{ width: "80%", padding: "8px" }}>
                                <span>{startDate}</span>
                            </div>
                            <div style={{ width: "20%" }}>
                                <a style={{ float: "right" }}>
                                    <DatePicker
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        adjustDateOnChange
                                        onChange={(date) => startDateChange(date)}
                                        customInput={<img
                                            style={{
                                                width: "35px",
                                                height: "37px",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                            }}
                                            src={ImageName.IMAGE_NAME.CALENDER4}
                                        />}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="web-form-bx">
                        <div className="frm-label">
                            End Date *
                        </div>

                        <div
                            className="input-group"
                            style={{
                                width: "100%",
                                borderRadius: "9px",
                                height: "43px",
                                border: "1px solid #ced4da",
                                boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                            }}
                        >
                            <div style={{ width: "80%", padding: "8px" }}>
                                <span>{endDate}</span>
                            </div>
                            <div style={{ width: "20%" }}>
                                <a style={{ float: "right" }}>
                                    <DatePicker
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        adjustDateOnChange
                                        onChange={(date) => endDateChange(date)}
                                        customInput={<img
                                            style={{
                                                width: "35px",
                                                height: "37px",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                            }}
                                            src={ImageName.IMAGE_NAME.CALENDER4}
                                        />}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <Button variant="primary" onClick={() => setDateRange()}>Set Date Range</Button>
                </>
            )
        } else {
            return <></>
        }

    }

    return (<>
        <div className="web-form-bx">
            <SelectBox
                optionData={CommonData.COMMON.DATE_RANGE_TYPE}
                value={value}
                onSelectChange={(value) => changeOption(value)}
            ></SelectBox>
        </div>
        {renderSelectedView(value)}
    </>
    )
}


export default VendorBirthdateFilter;